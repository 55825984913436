<template>
  <div class="text-center w-100 mb-12">
    <TTBtn
      color="white"
      elevation="1"
      fab
      large
      data-test="aside-right-btn-help"
      class="mb-2 v-btn--aside"
      @click="$root.$emit('addUser')"
    >
      <VIcon
        color="tt-black"
        size="19"
      >
        fal fa-user-plus
      </VIcon>
    </TTBtn>
    <div class="tt-text-caption tt-black--text text--lighten-2">
      Добавить<br>
      сотрудника
    </div>
    <VMenu
      v-model="closeMenuDialog"
      offset-x
      content-class="menu-card"
      :nudge-left="56"
      max-width="466"
      :close-on-content-click="false"
      left
    >
      <template #activator="{ on }">
        <TTBtn
          fab
          color="white"
          elevation="1"
          large
          class="mt-6 mb-2"
          v-on="on"
        >
          <VIcon
            color="tt-black"
            size="19"
          >
            fal fa-arrow-to-bottom
          </VIcon>
        </TTBtn>
      </template>
      <VCard class="pa-6 card-border--bordered">
        <VRow>
          <VCol
            cols="12"
          >
            <h2 class="tt-text-title-2 mb-0">
              Выгрузка отчёта
            </h2>
          </VCol>
          <VCol
            cols="12"
          >
            <TTSelect
              v-model="locationId"
              class="tt-select-attach"
              attach
              :items="locations"
              data-test="staff-select-location"
              hide-details="auto"
              item-text="name"
              item-value="id"
              label="Локация"
              :menu-props="{ auto : true }"
              large
              placeholder="Выбор локации"
            >
              <template #selection="{item,disabled}">
                <TextTemplate
                  :disabled="disabled"
                  :show-prefix="!item.is_active && !!item.id"
                  :text="item.name"
                  class="v-select__selection v-select__selection--comma"
                />
              </template>
              <template #item="{item}">
                <TextTemplate
                  :show-prefix="!item.is_active && !!item.id"
                  :text="item.name"
                />
              </template>
            </TTSelect>
          </VCol>
          <VCol
            cols="12"
          >
            <TTSelect
              v-model="staffPeriod"
              :items="periods"
              data-test="staff-select-location"
              hide-details="auto"
              item-text="name"
              item-value="id"
              label="Период добавления сотрудника"
              large
            />
          </VCol>
          <VCol
            v-if="staffPeriod === 2"
            class="pt-3"
            cols="6"
          >
            <h3 class="tt-text-body-2 mb-0">
              Даты отчёта
            </h3>
          </VCol>
          <VCol
            v-if="staffPeriod === 2 && datePeriod.length"
            class="pt-2 text-right"
            cols="6"
          >
            <span
              class="tt-dark-blue--text cursor--pointer"
              @click="handlePeriodChange([])"
            >
              Сбросить
            </span>
          </VCol>
          <VCol
            v-if="staffPeriod === 2"
            class="pt-0 pb-3"
            cols="12"
          >
            <SRangePicker
              :max-date="dateNow"
              :value="datePeriod"
              append-icon="fal fa-calendar-alt"
              data-test="app-staff-aside-right-search-period"
              hide-details="auto"
              placeholder="Выберите период"
              single-line
              @input="handlePeriodChange"
            />
          </VCol>
          <VCol
            class="d-flex justify-end"
            cols="12"
          >
            <TTCheckbox
              v-model="showFired"
              data-test-label="app-staff-aside-checkbox-dismissed"
              class="mt-0 pt-0"
              label="Включая уволенных"
            />
            <TTBtn
              class="mr-6"
              color="tt-ghost--text"
              data-test="app-staff-aside-button-close"
              @click="closeMenu"
            >
              Отменить
            </TTBtn>
            <TTBtn
              data-test="app-staff-aside-button-download-report"
              @click="downloadReport"
            >
              Выгрузить отчет
            </TTBtn>
          </VCol>
        </VRow>
      </VCard>
    </VMenu>
    <div class="tt-text-caption tt-black--text text--lighten-2">
      Выгрузить<br>
      отчет
    </div>
  </div>
</template>

<script>
import * as snamiApi from '@/services/backend/snami';
import SRangePicker from '@/components/ui/SRangePicker.vue';
import { downloadCSVLink } from '@/utils/CSV';
import TextTemplate from '@/components/shared/TextTemplate.vue';

export default {
  name: 'AppStaffAsideRight',
  components: { TextTemplate, SRangePicker },
  data() {
    return {
      closeMenuDialog: false,
      locationId: null,
      locations: [],
      defaultLocation: {
        id: null,
        name: 'Все локации',
      },
      staffPeriod: 1,
      periods: [
        {
          id: 1,
          name: 'За все время',
        },
        {
          id: 2,
          name: 'За выбранный период',
        },
      ],
      datePeriod: [],
      dateNow: this.$dayjs().format('YYYY-MM-DD'),
      startDate: '',
      endDate: '',
      showFired: false,
    };
  },
  created() {
    this.$repositories.location.list({ filter: {} }).then((r) => {
      const { data } = r.data;
      this.locations = data;
      this.locations.unshift(this.defaultLocation);
    }).catch((e) => console.warn(e));
  },
  methods: {
    handlePeriodChange(period) {
      this.datePeriod = period;
    },
    closeMenu() {
      this.closeMenuDialog = false;
      this.locationId = null;
      this.datePeriod = [];
      this.staffPeriod = 1;
    },
    async downloadReport() {
      if (this.locationId === null) {
        this.locationId = '';
      }
      this.startDate = this.datePeriod[0] || '';
      this.endDate = this.datePeriod[1] || '';
      if (this.staffPeriod === 1) {
        [this.startDate, this.endDate] = ['', ''];
        this.datePeriod = [];
      }
      const link = '/report/staff/summary?created_from=';
      // eslint-disable-next-line
      const workLink = `${link}${this.startDate}&created_till=${this.endDate}&location_id=${this.locationId}&show_fired=${this.showFired}`;
      await snamiApi.signLink({ link: workLink }).then((result) => {
        downloadCSVLink(result.data.signed_link);
        if (this.locationId === '') {
          this.locationId = null;
        }
      }).catch((e) => console.warn(e));
    },
  },
};
</script>

<style lang="scss" scoped>
.card-border--bordered{
  border: 1px solid #EBECED!important;
  border-radius: inherit;
  box-shadow: 0 2px 24px rgba(11, 18, 24, 0.08)!important;
}
/* Позволяет вставлять селекты и не обрезая контент*/
.menu-card{
  contain: unset;
  overflow: visible;
}
/* stylelint-ignore no-empty-source */
</style>
